/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import uplevyl1 from '../../assets/icons/Uplevyl1.svg';

const ConfirmationPage: React.FC = () => {
    const params = useParams();
    const [showCopied, setShowCopied] = useState<boolean>(false);
    const newLink = `https://${window.location.hostname}#invitee=${params.username}`;

    const handleClick = () => {
        setShowCopied(true);
        navigator.clipboard.writeText(newLink);
        setTimeout(() => {
            setShowCopied(false);
        }, 1000);
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-white p-4">
            <div className="w-full max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 py-8 text-center">
                <img
                    alt="Uplevyl"
                    className="w-40 sm:w-48 md:w-56 mx-auto mb-6"
                    src={uplevyl1}
                />
                <h1 className="mb-4 text-base sm:text-lg md:text-xl font-bold text-gray-800">
                    Thank You!
                </h1>
                <div className="mb-6 text-sm sm:text-base md:text-center">
                    <p className="mb-4">
                    Woohoo! Your Future Forward application has been submitted successfully! You will receive an email with the payment details for the program.
                    </p>
                    <p className="mb-4">
                    Want to review the highlights of the Future Forward program? Please <a href="https://www.uplevyl.com/future-forward" className="text-black underline hover:text-gray-600">visit this page</a>.
                    </p>
                    <p className="mb-4">
                    In the meantime, start Uplevyling right away by listening to an episode or two from the Uplevyl podcast, <a href="https://go.uplevyl.com/podcast" className="text-black underline hover:text-gray-600">Women. Wisdom. Worth.</a>.
                    </p>
                    <p className="mb-4">
                    Thank you for choosing to Uplevyl with us. We&apos;re excited to have you on board!
                    </p>
                    <p>
                    Team Uplevyl

                    </p>
                </div>
                <div className="flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-4 mt-6">
                    <div 
                        className="w-full sm:w-auto border-black border rounded py-2 px-4 text-sm break-all cursor-pointer text-center"
                        onClick={handleClick}
                    >
                        {newLink}
                    </div>
                    <button
                        className="w-full sm:w-auto text-white py-2 bg-black rounded px-5 cursor-pointer hover:bg-gray-800 transition-colors"
                        onClick={handleClick}
                    >
                        {showCopied ? 'Copied!' : 'Copy'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationPage;