import React, { useEffect, useState, } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAnalyticsService } from '../Intake/hooks/useAnalyticsService';
import ErrorMessage from './components/ErrorMessage';
import { useGetUserInfoQuery } from './hooks/useGetUserInfoQuery';
import { useUpdateUserMutation } from './hooks/useUpdateUserMutation';
import CreateAccount from './CreateAccount';
import { useActivateUserAccount } from './graphql/userGQL';

const ActivatePage = () => {
	const navigate = useNavigate();
	const analytics = useAnalyticsService();
	const { token } = useParams<{ token: string }>();
	const { data, error, loading } = useGetUserInfoQuery({
		variables: {
			token,
		},
	});
	const [updateUser] =
		useUpdateUserMutation();
	const [{ error: activateError }, activateUser] = useActivateUserAccount();

	// const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>();


	const setClickedLink = async () => {
		if (!data?.invite.id) return;
		await updateUser({
			variables: {
				id: data.invite.id,
				input: {
					clickedActivationLink: true,
				},
			},
			context: {
				headers: {
					Authorization: `Bearer ${data?.invite.accessToken}`,
				},
			},
		});
	};

	// If user has not been registered as clicking on the link in email, register here
	useEffect(() => {
		if (data?.invite.id && !data?.invite.clickedActivationLink) {
			setClickedLink();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		const sendEvent = async () => {
			await analytics.logScreenView({
				screen_name: 'password creation',
			});
		};
		sendEvent();
	}, [analytics]);

	useEffect(() => {
		setTimeout(() => {
			const iframe: any = document.querySelector(
				'iframe[style*="z-index: 2147483647"]',
			);
			if (iframe) {
				iframe.style.display = 'none';
			}
		}, 4000);
		localStorage.setItem('paymentToken', token)
	}, [token]);

	/**
	 * Set a user's password when form is submitted
	 */
	const handleSubmit = async (password: string) => {
		setErrorMessage(undefined);

		try {
			const res: any = await activateUser({
				token,
				email: data?.invite?.email,
				password
			});
			if (res.data?.userActivateAccount) { navigate('/payment-redirect') }
		} catch (err) {
			if (
				activateError &&
				activateError.graphQLErrors[0].extensions?.code === 'BAD_PASSWORD'
			) {
				console.log('update')
			} else {
				setErrorMessage(
					activateError?.graphQLErrors[0].message ??
					'An unknown error occured',
				);
			}
		} finally {
			if (!activateError)
				console.log(activateError)
		}
	};
	// 	getCheckout({
	// 		variables: {
	// 			type: UserCheckoutType.YEARLY,
	// 		},
	// 		context: {
	// 			headers: {
	// 				Authorization: `Bearer ${data.invite.accessToken}`,
	// 			},
	// 		},
	// 	}).then((res)=> {
	// 		if(res?.error) {
	// 			setErrorMessage(res.errors?.[0]?.extensions?.message?.[0])
	// 		}
	// 	});

	// };

	return (
		<>
			{error ? (
				<ErrorMessage error={error} />
			) : loading || !data ? (
				<h3 className="text-xl">
					We&apos;re just fetching your information, one
					sec...
				</h3>
			) : (
				<>
					<CreateAccount errorMessage={errorMessage} handleSubmit={handleSubmit} data={data?.invite} />
				</>
			)}
		</>
	);
};

export default ActivatePage;
