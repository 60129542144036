/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import Button from '../../components/Button';
import { useGetUserCheckoutQuery } from './hooks/useGetUserCheckoutQuery';
import { UserCheckoutType } from './graphql/queries/getUserCheckout';

const PaymentRedirect = () => {
	const showExplainer = true
	// const allowFreeTrial = true
	const stripe = useStripe();

	const [yearly, setYearly] = useState<boolean>(true);

	const [getCheckout, { data, loading }] = useGetUserCheckoutQuery();

	const handleSubmit = () => {
		getCheckout({
			variables: {
				type: yearly
					? UserCheckoutType.YEARLY
					: UserCheckoutType.MONTHLY,
			},
			context: {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('paymentToken')}`,
				},
			},
		});
	};

	useEffect(() => {
		if (!data?.paymentCreateCheckout.sessionID) return;
		stripe?.redirectToCheckout({
			sessionId: data?.paymentCreateCheckout.sessionID,
		});
	}, [data, stripe]);

	return (
		<div className="mt-40 flex items-center flex-col">
			{showExplainer && (
				<div className="flex flex-col items-center">
					<span className="text-3xl font-bold">Welcome</span>
					<p className="font-light mb-5">
						It’s now time to start your Uplevyl Membership.
					</p>
				</div>
			)}
			{/* {allowFreeTrial && (
				<p className="font-light mt-2 mb-5">
					Each one comes with a{' '}
					<span className="font-bold">free 90 day free trial, cancel anytime</span>.
				</p>
			)} */}
			<div className="flex mb-5 lg:w-1/2 w-2/3 items-center gap-2">
				{/* Monthly Card */}
				<div
					className={`py-8 px-5 md:px-10 rounded-lg mr-3 cursor-pointer  w-1/2 border flex transition-all duration-300 transform ${!yearly
						? 'gradient-selected shadow-lg scale-105  border-transparent ' // Selected styles with animation
						: 'bg-transparent border-yellow-600 scale-100' // Deselected styles
						}`}
					onClick={() => setYearly(false)}
				>
					<div className="flex flex-row justify-center items-end w-full">
						<h2 className="font-bold text-2xl md:text-3xl font-button">$150</h2>
						<p className="font-light">/month</p>
					</div>
				</div>
				{/* Yearly Card */}
				<div
					className={`py-8 px-5 md:px-10 rounded-lg cursor-pointer border  w-1/2 flex transition-all duration-300 transform ${yearly
						? 'gradient-selected shadow-lg scale-105 border-transparent' // Selected styles with animation
						: 'bg-transparent border-yellow-600 scale-100' // Deselected styles
						}`}
					onClick={() => setYearly(true)}
				>
					<div className="flex flex-row justify-center w-full  items-end">
						<h2 className="font-bold text-2xl md:text-3xl font-button">$1599</h2>
						<p className="font-light">/year</p>
					</div>
				</div>
			</div>
			<div>
				<Button
					type="submit"
					value="submit"
					disabled={loading}
					onClick={handleSubmit}
					className='px-6'
				>
					{!loading ? 'Continue' : 'Loading...'}
				</Button>
			</div>
		</div>
	);
};

export default PaymentRedirect;
