
export type InputName =
	| 'firstName'
	| 'lastName'
	| 'email'
	| 'phone'
	| 'highestEducationLevel'
	| 'linkedInProfile'
	| 'socialHandle'
	| 'generation'
	| 'companyAnnualRevenue'
	| 'howCanUplevylElevateYou'
	| 'companyName'
	| 'currentTitle'
	| 'aiSkillsRatings'
	| 'contributeViaWorkshops'
	| 'additionalInterests'
	| 'location'
	| 'digital'
	| 'motivations'
	| 'digitalImpactVision'
	| 'hearAboutProgram'
	| 'aiTopicsInterest';

export type IntakeQuestion = {
	type: 'text' | 'text1' | 'input' | 'choices' | 'phone' | 'role' | 'revenue' | 'company' | 'rating' | 'hybrid' | 'yesno' | 'futureforward' | 'multi-select';
	label: string | string[];
	inputName?: InputName;
	description: string[];
	button?: string;
	choices?: string[];
	svg?: string;
	min?: number;
	required: boolean;
	injectVariable?: {
		from: InputName;
		to: string;
	};
};

const data: IntakeQuestion[] = [
	// {
	// 	type: 'text',
	// 	label: 'Don’t Get Left Behind in the Digital Age: Apply To Join Future Forward Now',
	// 	description: [
	// 		'Are you prepared for the rapid changes reshaping the business landscape?',
	// 		'• According to a study by McKinsey, 92% of organizations consider digital transformation a top priority.',
	// 		'• Analysis by the National Skills Coalition (NSC) in partnership with the Federal Reserve Bank of Atlanta finds that 92% of jobs analyzed require digital skills.',
	// 		'• A survey conducted by Harvard Business Review found that 78% of respondents believe that digital leadership skills are crucial for navigating the complexities of a rapidly evolving business environment.',
	// 		'The demand for leaders equipped with digital acumen has never been higher. The next five years will define which leaders succeed and which are left behind.',
	// 		'Don’t wait until you’re sidelined - act now to protect your future.',
	// 		'Join Future Forward—An Exclusive Opportunity from Uplevyl',
	// 		'Future Forward, brought to you by Uplevyl is a selective membership program designed to give you the skills, insights, and network you need to lead in the digital age. Now is the time to invest in yourself to prevent career stagnation and avoid missing out on the senior and boardroom opportunities that are now reserved for those with digital acumen.',
	// 		'Act Now: Complete Your Membership Application',
	// 		'The application process is straightforward, private, and secure.',
	// 		'However, spaces are limited, and this opportunity won’t last. Submitting an application does not guarantee membership, nor are you obligated to join if accepted. You will receive an email regarding your application status within 5-7 business days.',
	// 		'If your application is successful, you will receive sign-up and payment details.',
	// 		'Don’t get left behind. AI is hurtling towards becoming a $20 trillion-sized economy, and companies are shifting rapidly towards digital-first strategies. Seize this chance to elevate your career and ensure your place as a leader in the new digital age.',
	// 	],
	// 	button: 'Continue',
	// 	required: false,
	// 	svg: '/assets/intake/intro.png',
	// },

	// {
	// 	type: 'text',
	// 	label: 'The next step is to complete our membership application form (don’t worry—five minutes tops).',
	// 	description: [
	// 		'Please note, we have the highest standards of privacy and encryption to protect your personal information. Knowing a little bit about you will help us personalize content just for you.',
	// 		'Ready to Uplevyl? Let’s get started.',
	// 	],
	// 	button: 'Begin Application',
	// 	required: true,
	// },
	{
		type: 'hybrid',
		label: ['Now that AI is here, do any of these concern you?'
		],
		inputName: 'motivations',
		description: [''],
		choices: [
			'My skillset may start to become irrelevant  ',
			'I may fall behind and put my job at risk ',
			'I may start to miss out on salary increases ',
			'I may miss out on promotions ',
			'My status/gravitas in the organization may get diminished ',
			'I may be less likely to get a seat on a company board ',
			'All of the above ',
		],
		required: true
	},
	{
		type: 'hybrid',
		label: ['How do you envision using your strengthened digital acumen to impact your ',
			'organization, community or industry?'
		],
		inputName: 'digitalImpactVision',
		description: [''],
		choices: [
			'Drive innovation and efficiency',
			'Shape ethical AI practices that foster inclusivity',
			'Bridge technology and people',
			'Champion digital literacy within my industry ',
			'Lead my organization toward a digital-first mindset',
			'Inspire and support other women to embrace digital tools',
			'Other'
		],
		required: true
	},
	{
		type: 'yesno',
		label: ['Do you like the idea of contributing, learning, and networking via virtual',
			'workshops and chat discussions with other senior women leaders in the',
			'community?'
		],
		inputName: 'contributeViaWorkshops',
		description: [''],
		button: 'Next',
		required: true
	},
	{
		type: 'input',
		label: [
			'We’re excited to get to know you.',
			'Let’s get started! What’s your first name?',
		],
		inputName: 'firstName',
		svg: '/assets/intake/book.png',
		description: [],
		button: 'Next',
		required: true,
	},
	{
		type: 'input',
		label: [
			"Welcome, {FIRST NAME}! What's your last name?",
		],
		inputName: 'lastName',
		description: [],
		button: 'Next',
		required: true,
		injectVariable: {
			from: 'firstName',
			to: '{FIRST NAME}',
		},
	},
	{
		type: 'input',
		label: 'What’s the best email to reach you?*',
		inputName: 'email',
		description: [],
		button: 'Next',
		required: true,
	},
	{
		type: 'phone',
		label: 'What’s the best contact number for you?*',
		svg: '/assets/intake/pc.png',
		inputName: 'phone',
		description: [
			'This will help prevent fraud & helps us verify you as a valid user',
		],
		button: 'Next',
		required: true,
	},
	{
		type: 'input',
		label: [
			'What is your location?',
		],
		inputName: 'location',
		description: [],
		button: 'Next',
		required: true,
	},
	{
		type: 'input',
		label: [
			'What is your current job title or role?',
		],
		inputName: 'currentTitle',
		description: [],
		button: 'Next',
		required: true,
	},
	{
		type: 'input',
		label: [
			'What is your current company name?',
		],
		inputName: 'companyName',
		description: [],
		button: 'Next',
		required: true,
	},
	{
		type: 'choices',
		label: "What's the highest level of education you've achieved?*",
		inputName: 'highestEducationLevel',
		description: [],
		choices: [
			'High School',
			"Associate's Degree",
			"Bachelor's Degree",
			"Master's Degree",
			'Doctorate',
		],
		button: 'Next',
		required: true,
	},
	{
		type: 'input',
		label: [
			'How did you hear about our Future Forward program? ',
		],
		inputName: 'hearAboutProgram',
		description: [],
		// choices: [
		// 	'Linkedin',
		// 	"Facebook",
		// 	"Instagram",
		// 	"Uplevyl Email Newsletter",
		// 	'Partner Email Newsletter',
		// 	'Referral',
		// 	'Search Engine',
		// ],
		button: 'Next',
		required: true,
	},
	{
		type: 'rating',
		label: ['Please rate your current familiarity with the following AI-related skills',
			'(1-5, with 5 being advanced):'
		],
		inputName: 'aiSkillsRatings',
		description: [''],
		choices: [
			'Understanding AI Concepts',
			'Using AI Tools (e.g., chatbots, data analytics)',
			'Data Analysis Techniques',
			'Data Privacy and Ethics',
			'Digital Communication Platforms'
		],
		required: true
	},
	{
		type: 'input',
		label: [
			'Which specific AI-related topics are you interested in learning more about? ',
			'(e.g. AI concepts, AI tools, Data Literacy, AI and Data ethics, Leadership in',
			'the AI age, etc.)?'
		],
		inputName: 'aiTopicsInterest',
		description: [],
		button: 'Next',
		required: true,
	},
	{
		type: 'input',
		label: [
			'Is there anything else you would like us to know about your interests or',
			'background? ',
		],
		inputName: 'additionalInterests',
		description: [],
		button: 'Next',
		required: true,
	},
	{
		type: 'input',
		label: [
			'Please share your LinkedIn profile. (optional)', 'Last question! Well done, you!!'
		],
		inputName: 'linkedInProfile',
		description: [],
		button: 'Next',
		required: false,
	},
	// {
	// 	type: 'input',
	// 	label: [
	// 		'What is your Instagram and / or Twitter handle',
	// 		'(portfolio website)?',
	// 	],
	// 	inputName: 'socialHandle',
	// 	description: [],
	// 	button: 'Next',
	// 	required: true,
	// },
	// {
	// 	type: 'input',
	// 	label: [
	// 		'How do you envision using your digital skills to impact your organization,',
	// 		'community or industry?'
	// 	],
	// 	inputName: 'digital',
	// 	description: [],
	// 	button: 'Next',
	// 	required: true,
	// },

	{
		type: 'text1',
		label: [
			'Thank you for being proactive and taking action.',
			'We are looking forward to your application.',
		],
		description: [],
		required: false,
	},
];

export default data;
